/* Paginator.css */
.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-prev {
  background-color: #412889;
  border-radius: 5px;        
  width: 35px;
  height: 35px;
  text-align: center;
  color:  #FFFFFF;
  font-weight: 500;
  margin: 2px;
}


.p-paginator .p-paginator-pages .p-paginator-page:hover,
.p-paginator .p-paginator-next:not(.p-disabled):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):hover {
  /* background-color: #e0e0e0;   */
  background-color: #412889; 
  cursor: pointer;
}

#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div > div > div.datatable-footer > div > div > button.p-paginator-prev.p-paginator-element.p-link.p-disabled {
    display: none;
}

#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div > div > div.datatable-footer > div > div > button.p-paginator-next.p-paginator-element.p-link.p-disabled {
    display: none;
}

#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div > div > div.datatable-footer > div > div > button.p-paginator-next.p-paginator-element.p-link {
    display: none;
}

#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div > div > div.datatable-footer > div > div > button.p-paginator-prev.p-paginator-element.p-link {
    display: none;
}

/* Paginator custom styles */
.paginator-custom .p-paginator-pages {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .paginator-custom .p-paginator-pages li {
    display: inline-block;
    margin-right: 4px;
  }
  
  .paginator-custom .p-paginator-pages li a {
    display: block;
    padding: 8px 12px;
    border-radius: 4px;
    color: #333;
    background-color: transparent;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .paginator-custom .p-paginator-pages li a:hover {
    background-color: #f0f0f0;
  }
  
  .paginator-custom .p-paginator-pages li.p-paginator-page.p-highlight a {
    background-color: #007bff; /* Highlight color for active page */
    color: #fff;
  }
  
  .paginator-custom .p-paginator-pages li.p-paginator-page.p-disabled a {
    pointer-events: none;
    color: #ccc;
  }
  
  /* Add this to your CSS file */
  /* for no filter datatable css */
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .pagination-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
  }
  
  .pagination-link {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #333A48;
    text-decoration: none;
  }
  
  .pagination-link:hover {
    color: #333A48;
  }
  
  .pagination-active {
    background-color: #F0950C;
    color: #FFFFFF !important;
    border-radius: 5px;
  }
  
  .pagination-prev, .pagination-next {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pagination-prev img, .pagination-next img {
    width: 16px;
    height: 16px;
  }
  
/* // end no filter datatable */