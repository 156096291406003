.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: blue;
  color: white;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* my css */
.toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -25px;
}

/* end my css */
/* datatable-styles.css */
.datatable-custom .p-datatable-tbody > tr > td {
  font-size: 12px; /* Adjust this value as needed */
}
.datatable-custom .p-datatable-thead {
  font-size: 14px; /* Adjust this value as needed */
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 8px 15px;
  width: 15px;
}
/* .p-datatable-table .p-datatable-thead > tr > th {
  padding: 8px 15px;
  width: 15px;
} */
.p-fluid .p-column-filter-element {
  background: #ffffff;
  padding: 4px 4px;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 4px;
  outline-color: transparent;
  height:31px;
  font-weight: 500;
  font-size: 12px;
}
.datatable-custom-header {
  font-size: 14px; /* Adjust this value as needed */
}
.datatable-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.paginator-left {
  text-align: left;
  padding: 10px;
}

.paginator-right {
  text-align: right;
  padding: 10px;
}
.small-font-column {
  font-size: 10px; /* Adjust this value for a specific column */
}
/* .p-column-filter-menu-button .p-icon {
  display: none;
} */
/* .table-header .p-inputtext {
  width: 100%;
  padding-left: 0.5rem; 
  box-sizing: border-box;
} */
/* end datatable-styles.css */

/* loader css all pages */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* select option search css time slote */
#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div.px-\[18px\].pt-\[5px\].bg-\[\#F8F9FC\].pb-\[5px\] > div > div > div > div > div.relative.bg-white.rounded-lg.w-full.max-w-4xl.p-2.md\:p-4 > div.px-\[1px\].pt-\[4px\].bg-\[\#F8F9FC\].pb-\[0px\] > div > div:nth-child(1) > div
{
  height:42px!important;  
}
.css-13cymwt-control
{
  margin-top:-9px!important;
  border:none!important;
}
.css-1u9des2-indicatorSeparator
{
  display:none!important;
}
.css-1xc3v61-indicatorContainer
{
  border:none!important;
}
.css-1nmdiq5-menu
{
  margin-left:-10px!important;
}

.css-t3ipsp-control
{
  border: none!important;
    margin-top: -9px;
}
.css-t3ipsp-control:hover
{
  border:none!important;
}
.custom-select_control
{
  width:400px!important;
}
select option:hover {
  background-color: #F0F0F0 !important; /* Your desired hover color */
  color: #2B2F32 !important; /* Text color on hover */
}
#root > div > div > div.flex-1.flex-col.relative.z-0.overflow-y-auto > div.md\:mx-auto.px-4.py-4 > div.px-\[18px\].pt-\[5px\].bg-\[\#F8F9FC\].pb-\[5px\] > div > div > div > div > div.relative.bg-white.rounded-lg.w-full.max-w-4xl.p-2.md\:p-4 > div.px-\[1px\].pt-\[4px\].bg-\[\#F8F9FC\].pb-\[0px\] > div > div:nth-child(2) > div
{
  height:42px!important; 
}
/* end select option search css time slote*/

.custom-option:hover {
  background-color: #FF0000!important; /* Red background on hover */
  color: #FFFFFF; /* Optional: Change text color to white on hover */
}

.p-column-title{
  color: #262626;
  
}
.datatable-custom .p-datatable-thead
{
  background-color: #F5F5F5;
  border-bottom: 0.5px solid #D9D9D9;
  display:flex;
  flex-direction: column;
}
.datatable-custom .p-datatable-thead tr:first-child{
  order: 3;
}
.datatable-custom .p-datatable-thead tr:first-child th{
  order: 3;
  background-color: #F5F5F5;
}
.datatable-custom .p-datatable-thead tr:nth-child(2) th{
  /* order: 3; */
  background-color: white;
}
.p-datatable .p-datatable-tbody > tr > td {
  vertical-align: middle;
  text-align: left;
  color: #262626;
  font-weight: 400;
}
.p-inputtext{
  font-weight: 500!important;
  outline: none;
}

.p-datatable .p-datatable-tbody{
  display: flex;
  flex-direction: column;
}
.p-datatable .p-datatable-tbody tr
{
  /* width: 155px;  */
  height: 38px; 
  border-bottom: 0.5px solid #D9D9D9; 
}

/* Remove border and focus outline */
.css-t3ipsp-control {
  border: none !important;
  outline: none !important;
}


/* new search dropdown css */
.css-jjcpk1-control {
  border: 1px solid red;
}

/* .css-1m9egap-control  */
.css-1nmdiq5-menu
{
  margin-left: 1px!important;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* end loader css all pages */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
