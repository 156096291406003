/* TableStyles.css */

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.75rem; /* Adjust the padding as needed */
}

.p-datatable .p-column-header-content {
  margin: 0;
  padding: 0;
}

.p-datatable .p-datatable-thead > tr > th {
  vertical-align: middle;
  text-align: left;
}

.p-datatable .p-datatable-tbody > tr > td {
  vertical-align: middle;
  text-align: left;
}
